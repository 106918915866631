<template>
	<div>
		<div class="container">
			<div class="handle-box">
				<el-select size="small" v-if="isAdmin" @change="agentChange" v-model="query.agentId" filterable
					clearable placeholder="代理商" class="handle-select mr10" style="width: 300px">
					<el-option v-for="item in agents" :key="item.value" :label="item.label"
						:value="item.value"></el-option>
				</el-select>
				<el-select size="small" v-if="isSubAgent" v-model="query.subAgentId" @change="getfetchDefaultCompanies"
					filterable clearable placeholder="子代理" class="handle-select mr10" style="width: 260px;">
					<el-option v-for="item in subAgentList" :key="item.id" :label="item.name"
						:value="item.id"></el-option>
				</el-select>
				<el-select size="small" v-if="isNormal || isAgent" v-model="query.companyId" @change="companyChange"
					filterable clearable placeholder="企业" class="handle-select mr10" style="width: 300px">
					<el-option v-for="item in companys" :key="item.value" :label="item.label"
						:value="item.value"></el-option>
				</el-select>
				<el-button size="small" type="primary" :class="[theme]" icon="el-icon-search"
					@click="handleSearch">搜索</el-button>
				<el-button size="small" type="primary" :class="[theme]" @click="handleDownload">导出</el-button>
			</div>
			<div style="display: flex;flex-direction: row;margin: 10px 0 20px 0;">
				<div style="margin-right: 20px;">
					<span>总企业数：</span>
					<span>{{ companySize || 0 }}家</span>
				</div>
				<div style="margin-right: 20px;">
					<span>总坐席数：</span>
					<span>{{ userSize || 0 }}个</span>
				</div>
				<div style="margin-right: 20px;">
					<span>正常使用：</span>
					<span>{{ use || 0 }}个</span>
				</div>
				<div style="margin-right: 20px;">
					<span>停用账号：</span>
					<span>{{ stop || 0 }}个</span>
				</div>
			</div>
			<el-table :data="tableData" border class="table" ref="multipleTable" header-cell-class-name="table-header"
				@selection-change="handleSelectionChange" @row-click="showGuoqiChildrens"
				:header-cell-style="{ 'text-align': 'center' }" :row-style="{ height: '20px' }"
				:cell-style="{ padding: '6px' }" :row-class-name="tableRowClassName">
				<el-table-column prop="companyName" width="340" label="公司" align="center"></el-table-column>
				<el-table-column prop="normalUseSize" label="正在使用账号" align="center"></el-table-column>
				<el-table-column prop="stopSize" label="停用账号" align="center"></el-table-column>
			</el-table>
			<div class="pagination">
				<el-pagination background layout="total, sizes, prev, pager, next, jumper"
					:current-page="query.pageIndex" :page-sizes="[30, 50, 100, 500]" :page-size="query.pageSize"
					:total="pageTotal" @size-change="handleSizeChange"
					@current-change="handlePageChange"></el-pagination>
			</div>

			<el-dialog :title="showGuoqiChildrenTitle" append-to-body v-model="showGuoqiChildren" width="500px"
				@close="closedialog">
				<el-form label-width="120px">
					<el-form-item label="公司名称:" style="margin: 0;">{{ companyName }}</el-form-item>
					<el-form-item label="账号"><el-input size="small" type="textarea" :rows="5"
							v-model="children2Data"></el-input></el-form-item>
				</el-form>
			</el-dialog>
		</div>
	</div>
</template>

<script>
	import { fetchUserById, getBlackList, blackListDelete, getbulkAdd } from '../api/userIndex';
	import { fetchDepartment, fetchDefaultUsers } from '../api/index.js';
	import { fetchAgent, fetchInitAgent } from '../api/agentIndex.js';
	import { fetchCompanyByAgentId, fetchDefaultCompanies } from '../api/companyIndex';
	import { downloadStatus, getfindStatus } from '../api/tag.js';
	import { querySubAgent } from '../api/serialIndex.js';
	export default {
		name: 'seating',
		data() {
			return {
				theme: localStorage.getItem('theme'),
				query: {
					companyId: '',
					agentId: '',
					pageIndex: 1,
					pageSize: 30,
					subAgentId: ''
				},
				agents: [],
				companys: [],
				departments: [],
				tableData: [],
				multipleSelection: [],
				delList: [],
				agentDis: false,
				isAdmin: false,
				isSubAgent: false,
				isAgent: false,
				isNormal: false,
				companyDis: false,
				pageTotal: 0,
				loading: false,
				invalidateDate: 0,
				role: '',
				addVisible: false,
				ImportVisible: false,
				blackremark: '',
				phoneNumber: '',
				form: {},
				companySize: '',
				stop: '',
				use: '',
				userSize: '',
				showGuoqiChildren: false,
				children2Data: [],
				showGuoqiChildrenTitle: '',
				companyName: ''
			};
		},
		created() {
			this.role = localStorage.getItem('ms_role');
			this.subAgent = localStorage.getItem('subAgent');
			this.init();
			this.getData();
			if (this.role === 'admin') {
				this.isAdmin = true;
				this.agentDis = true;
				this.companyDis = true;
				this.isNormal = true;
			}
			if (this.role === 'agent') {
				this.isAgent = true;
				this.companyDis = true;
				this.isNormal = true;
				if (this.subAgent == 'true') {
					this.isSubAgent = true;
					this.getquerySubAgent();
				} else {
					this.isSubAgent = false;
				}
			}
			if (this.role === 'subAgent') {
				this.isAgent = true;
			}
		},
		methods: {
			getfetchDefaultCompanies() {
				this.query.companyId = '';
				let data = {
					currentUserId: localStorage.getItem('user'),
					subAgentId: this.query.subAgentId
				};
				fetchDefaultCompanies(data).then(res => {
					this.companys = res.data;
					if (this.role == 'agent') {
						this.getData();
					}
				});
			},
			getquerySubAgent() {
				let data = {
					agentId: this.query.agentId,
					userId: localStorage.getItem('user')
				};
				querySubAgent(data).then(res => {
					if (res.code == 200) {
						this.subAgentList = res.data;
					} else {
						this.$message.error(res.message);
					}
				});
			},
			tableRowClassName({ rowIndex }) {
				if ((rowIndex + 1) % 2 === 0) {
					return 'oddRow';
				}
				return 'evenRow';
			},
			init() {
				let data = {
					currentUserId: localStorage.getItem('user')
				};
				fetchInitAgent(data).then(res => {
					if (res.data) {
						this.invalidateDate = res.data.validCallTime;
					}
				});

				fetchAgent(localStorage.getItem('user')).then(res => {
					this.agents = res.data;
				});

				if (this.subAgent == true) {
					this.isSubAgent = true;
					this.getquerySubAgent();
				}
				this.getfetchDefaultCompanies();
			},
			getData() {
				let data = {
					agentId: this.query.agentId,
					companyId: this.query.companyId,
					userId: localStorage.getItem('user'),
					pageIndex: this.query.pageIndex,
					pageSize: this.query.pageSize
				};
				getfindStatus(data).then(res => {
					if (res.code == 200) {
						this.tableData = res.data.pageHelper.list;
						this.pageTotal = res.data.pageHelper.total;
						this.companySize = res.data.companySize;
						this.stop = res.data.stop;
						this.use = res.data.use;
						this.userSize = res.data.userSize;
					} else {
						this.$message.error(res.message);
					}
				});
			},
			agentChange(agentId) {
				this.query.agentId = agentId;
				this.query.companyId = '';
				this.query.departmentId = '';
				this.query.userId = '';
				this.form.agentId = agentId;
				this.form.companyId = '';
				this.form.departmentId = '';
				this.form.userId = '';
				if (agentId) {
					let data = {
						subAgent: JSON.parse(localStorage.getItem('subAgent').toLowerCase()),
						agentId: agentId
					};
					fetchCompanyByAgentId(data).then(res => {
						this.companys = res.data;
					});
				}
			},
			companyChange(companyId) {
				this.query.companyId = companyId;
				this.query.departmentId = '';
				this.query.userId = '';
				this.form.companyId = companyId;
				this.form.departmentId = '';
				this.form.userId = '';
				let data = {
					companyId: companyId,
					currentUserId: localStorage.getItem('user')
				};
				fetchDepartment(data).then(res => {
					this.departments = res.data;
					this.query.pageIndex = 1;
					this.getData();
				});
			},
			//导出
			handleDownload() {
				this.query.userId = localStorage.getItem('user');
				downloadStatus(this.query).then(res => {
					const blob = new Blob([res], {
						type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
					});
					let downloadElement = document.createElement('a');
					let href = window.URL.createObjectURL(blob);
					downloadElement.href = href;
					let fileName = '坐席数据' + '.xlsx';
					downloadElement.download = fileName;
					document.body.appendChild(downloadElement);
					downloadElement.click();
					document.body.removeChild(downloadElement);
					window.URL.revokeObjectURL(href);
				});
			},
			// 触发搜索按钮
			handleSearch() {
				this.query.pageIndex = 1;
				this.getData();
			},
			// 分页导航
			handlePageChange(val) {
				this.query.pageIndex = val;
				this.getData();
			},
			handleSizeChange(val) {
				this.query.pageSize = val;
				this.getData();
			},

			showGuoqiChildrens(row, column) {
				this.showGuoqiChildrenTitle = column.label;
				this.children2Data = [];
				if (column.label == '正在使用账号') {
					if (row.normalUse.length > 0) {
						this.showGuoqiChildren = true;
						let resultData = row.normalUse;
						for (var int = 0; int < resultData.length; int++) {
							this.children2Data += resultData[int] + '\r\n';
						}
						this.companyName = row.companyName;
					}
				} else if (column.label == '停用账号') {
					if (row.stop.length > 0) {
						this.showGuoqiChildren = true;
						let resultData = row.stop;
						for (var int = 0; int < resultData.length; int++) {
							this.children2Data += resultData[int] + '\r\n';
						}
						this.companyName = row.companyName;
					}
				}
			},
			closedialog() {
				console.log(this.companyName, 'this.companyName');
				this.showGuoqiChildren = false;
				this.companyName = '';
			}
		}
	};
</script>

<style scoped>
	.handle-box {
		margin-bottom: 20px;
	}

	.down {
		border: 1px solid #dcdfe6;
		background: #4f7afd;
		color: #ffffff;
		transition: 0.1s;
		font-weight: 500;
		padding: 8px 16px;
		font-size: 12px;
		border-radius: 4px;
		margin-left: 10px;

		display: inline-block;
	}

	.handle-select {
		width: 120px;
	}

	.handle-input {
		width: 300px;
		display: inline-block;
	}

	.table {
		width: 100%;
		font-size: 12px;
	}

	.red {
		color: #ff0000;
	}

	.mr10 {
		margin-right: 10px;
	}

	.grid-con-icon {
		font-size: 50px;
		width: 100px;
		height: 100px;
		text-align: center;
		line-height: 100px;
		color: #fff;
	}

	.grid-cont-right {
		flex: 1;
		text-align: center;
		font-size: 20px;
		font-weight: bold;
		color: #999;
	}

	.grid-content {
		display: flex;
		align-items: center;
		height: 100px;
	}
</style>
<style>
	.import-visible .el-upload {
		width: 100% !important;
		display: flex !important;
		flex-direction: column !important;
		align-items: center !important;
		justify-content: center !important;
	}
</style>